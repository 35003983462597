import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import clsx from 'clsx'

import Layout from '../components/layout'
import Header from '../components/header-new'
import SEO from '../components/seo'
import Intro from '../components/sections/intro/alt-new'
// import HomeContentAlt from '../components/sections/home-content/alt'
import Testimonials from '../components/sections/testimonials-new'
import Partners from '../components/sections/partners-new'
import Existing from '../components/apply/existing'

import heroImage from '../images/hero-new.png'
import securityList from '../images/secure.svg'

import planabImage from '../images/planab.png'
import plancImage from '../images/planc.png'
import planfImage from '../images/planf.png'
import plangImage from '../images/plang.png'
import phoneIcon from '../images/phone-white.svg'

import '../scss/new-font.scss'

import { toggleField } from '../reducers/captive'

import style from './styles.module.scss'

const phone = '866-740-2901'

const HomeCOne = ({ location, toggleField }) => {
  useEffect(() => {
    toggleField('homeOrigin', 'c1-new')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    window?.ga &&
      window.ga('send', 'screenview', {
        appName: 'medicarecompanion.org',
        screenName: 'landing',
      })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <Layout
      phone={phone}
      location={location}
      hideFooter
      includeCitySearch
      newFont
      showCountDown
    >
      <SEO
        title="Medicare Supplement Plans - Medicare Companion"
        keywords={[`medicare`, `insurance`, `plans`, `affordable`, `supplement`]}
      />
      <Header phone={phone} grayLogo />
      <Intro
        showTitle
        title="Get Additional Medicare Coverage"
        subTitle="Get a FREE quote in less than 2 minutes for better coverage."
        image={heroImage}
      >
        <div className="contacts-form alt text-center">
          <div className={style.purpleBox}>
            <Existing
              toggleApplyLayout
              landing
              type="medicareAlt"
              className="home-inline"
              title="Are you currently insured?"
              includeCitySearch
            />
          </div>
          <div className={style.securityList}>
            <img src={securityList} alt=" " />
          </div>
        </div>
      </Intro>
      <main id="main">
        <Partners showTitle={false} bg="bg-dark" />
        <Testimonials />
        <section className={clsx('container', style.info)}>
          <div>
            <h1>Why should I use Medicare Companion?</h1>
            <p>
              Medicare parts A & B are filed with and regulated by your state’s Department
              of Insurance. Whether you buy from Medicare Companion, your local agent, or
              directly from the health insurance company, you’ll pay the same monthly
              premium for the same plan.
            </p>

            <p>
              This means that you can enjoy the advantages and convenience of shopping and
              purchasing your additional Medicare coverage through Medicare Companion and
              rest assured that you’re getting the best available price.
            </p>
          </div>
          <div>
            <h1>Open Enrollment Period for additional Medicare insurance coverage</h1>
            <p>
              Medicare Open Enrollment Period begins the first day of the month in which
              you are at least 65 and covered under Medicare Part B. You have six months
              to buy any additional Medicare coverage with no limitations to plan options.
            </p>

            <p>
              If you are under 65, check with your state’s Social Security Administration
              to see if it offers additional open enrollment periods.
            </p>
          </div>
        </section>
        <section className={style.plansInfo}>
          <div className="container">
            <h1>
              Understanding Premiums with <br />
              Lower Out-of-Pocket Costs
            </h1>
            <div className={style.plansGrid}>
              <div className={style.planItem}>
                <div>
                  <img src={planabImage} alt=" " />
                </div>
                <div className={style.planRight}>
                  <h2>
                    Plans <span>A&amp;B</span>
                  </h2>
                  <p>
                    Medicare Plans A & B cover basic co-insurance and copayment costs for
                    hospitalization, medical expenses, and hospice care. Medicare Plan B
                    provides additional coverage for Part A deductibles.
                  </p>
                </div>
              </div>

              <div className={style.planItem}>
                <div>
                  <img src={plancImage} alt=" " />
                </div>
                <div className={style.planRight}>
                  <h2>
                    Plans <span>C</span>
                  </h2>
                  <p>
                    Medicare Supplement Plan C covers the basic Medicare benefits plus
                    skilled nursing care, certain Medicare Part A and Part B deductibles
                    and emergency care abroad.
                  </p>
                </div>
              </div>

              <div className={style.planItem}>
                <div>
                  <img src={planfImage} alt=" " />
                </div>
                <div className={style.planRight}>
                  <h2>
                    Plans <span>F</span>
                  </h2>
                  <p>
                    Covers the basic Medicare benefits, plus coverage for Medicare Part A
                    and B deductibles, skilled nursing care, emergency care abroad and
                    100% of Part B excess charges—this is the difference between what a
                    provider charges and the amount Medicare will pay up to Medicare’s
                    limiting amount. A high-deductible option is available that can lower
                    your premiums.
                  </p>
                </div>
              </div>

              <div className={style.planItem}>
                <div>
                  <img src={plangImage} alt=" " />
                </div>
                <div className={style.planRight}>
                  <h2>
                    Plans <span>G</span>
                  </h2>
                  <p>
                    Medicare Supplement Plan G covers basic Medicare benefits plus
                    coverage for additional costs like Part A deductibles, skilled nursing
                    care, Part B excess charges (charges above what Medicare pays) and
                    emergency care abroad.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className={style.cta}>
          <div className={clsx('container', style.ctaContainer)}>
            <h2>Still have questions about a plan? Give us a call!</h2>
            <div>
              <a href={`tel:`} className={style.phone}>
                <img src={phoneIcon} alt=" " />{' '}
                <div className={style.phoneText}>
                  <span className={style.phoneNumber}>{phone}</span>{' '}
                  <span className={style.tty}>TTY Users: 711</span>
                </div>
              </a>
            </div>
          </div>
        </section>
        <section className={style.faq}>
          <div className="container">
            <h2>Frequently Asked Questions</h2>
            <div className={style.faqList}>
              <div>
                <h4>Why should I use MedicareCompanion.org?</h4>
                <p>
                  MedicareCompanion.org helps you learn about your Medicare Plan options
                  and find a plan, all at once. Our licensed insurance agents can answer
                  your questions and help you find the right additional Medicare coverage
                  to fit your needs and budget.
                </p>
              </div>
              <div>
                <h4>Do I have to pay for your help?</h4>
                <p>
                  No, we provide our services at no charge to you. We are happy to answer
                  your Medicare-related questions, help you compare your plan options, and
                  decide if additional Medicare coverage is right for you.
                </p>
              </div>
              <div>
                <h4>What if I don't know what plan I need?</h4>
                <p>
                  Our US-based agents can help you assess what's important to you and find
                  an affordable plan that gives you peace of mind knowing you are covered.
                </p>
              </div>
              <div>
                <h4>What if I already have a Medicare coverage?</h4>
                <p>
                  If you already have Medicare coverage, but want to change plans or lower
                  your premiums, you are in the right place.{' '}
                  <strong>Simply call us at 1-866-405-7929</strong> or fill out the form
                  at the top or bottom of this page.
                </p>
              </div>
              <div>
                <h4>How do I get started?</h4>
                <p>
                  You can get started by filling out the form on the top or bottom of this
                  page or by calling us directly at 1-866-405-7929. We can help you
                  through the entire process, from helping find a plan that works for you
                  to submitting your application.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className={style.bottomCTA}>
          <h1>Find Affordable Medicare Plan Coverage</h1>
          <h5>First, just answer a few questions for us so we can help you.</h5>
          <button onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}>
            Find Medicare Plans
          </button>
        </section>
        <footer className={clsx('container', style.footer)}>
          <div className={style.cc}>
            &copy; 2022 MedicareCompanion. All Rights Reserved.
          </div>
          <div className={style.legalLinks}>
            <a target="_blank" href="/terms-of-service">
              Terms Of Services
            </a>
            <a target="_blank" href="/privacy-policy">
              Privacy Policy
            </a>
            <a target="_blank" href="/ccpa">
              CCPA
            </a>
          </div>
        </footer>
      </main>
    </Layout>
  )
}

export default connect(null, { toggleField })(HomeCOne)
